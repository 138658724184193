<template>
  <v-overlay
      v-if="isLoading"
      :absolute="true"
      :opacity="opacity"
  >
    <v-progress-circular indeterminate size="32"></v-progress-circular>
  </v-overlay>
</template>

<script>
  export default {
    name: "OverlayLoader",

    props: {
      isLoading: {
        required: true
      },
      absolute: {
        default: true
      },
      opacity: {
        default: '0.4'
      },
    }
  }
</script>

<style scoped>

</style>